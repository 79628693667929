//React
import {useEffect, useState} from 'react';
import {formatNumber} from '../../helpers/numeral'

//Modules
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/nb';

const VerticalBarChartNew = ({ primaryData, currentMonth, primaryColor, secondaryColor }) => {

    const [data, setData] = useState(undefined);
    const [currentMonthNumber, setCurrentMonthNumber] = useState(0);

    useEffect(() => {
        if (primaryData && primaryData.length > 0) {
            let newArr = []
            let curr = []
            let past = []
            let currM = moment(primaryData[primaryData.length - 1].timestamp).month()
            setCurrentMonthNumber(currM)

            primaryData.forEach(e => {
                let m = moment(e.timestamp).subtract(1, 'month');

                newArr.push({
                    timestamp: e.timestamp,
                    value: e.value,
                    year: m.year(),
                    month: m.month(),
                })
            })

            curr = newArr.filter(x => x.year == moment().year())
            past = newArr.filter(x => x.year == moment().subtract(1, 'year').year())

            let final = []


            for (let i = 0; i < 12; i++) {
                final.push({
                    current: curr.find(x => x && x.month === i),
                    previous: past.find(x => x && x.month === i),
                })
            }

            final[currM].current = getCurrentMonthProduction()
            setData(final)
        } else if (currentMonth) {
            let final = []
            const d = new Date();
            let month = d.getMonth();
            for (let i = 0; i < 12; i++) {
                if(i === month) {
                    final[i] = {
                        current: getCurrentMonthProduction(),
                        previous: 0,
                    }
                    setCurrentMonthNumber(i)
                } else {
                    final[i] = null
                }
            }
            setData(final)
        }
    }, [primaryData]);

    //Components
    const EmptyBars = ({count}) => {

        //Constants
        let temp = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

        return (
            temp
                ? temp.map((item, index) => (
                    item < count
                        ? <div
                            key={`empty_bar_${index}`}
                            className={`w-1/12 h-0 ml-6`}
                        />
                        : false
                ))
                : false
        )
    }

    const getMaxValue = () => {
        let current = getCurrentMonthProduction().value;
        if (primaryData && primaryData.length > 0) {
            let before = primaryData.reduce((max, obj) => (max.value > obj.value) ? max : obj).value
            if (current && current > before)
                return roundup(current)
            return roundup(before)
        } else if (current){
            return roundup(current)
        }
        return 1;
    }

    function roundup(v) {
        let numOfZeroes = Math.round(v).toString().length - 2;
        let pow = Math.pow(10, numOfZeroes)
        let result = (Math.round(v / pow) * pow)

        if (result > v)
            return result

        let diff = (v - result)
        numOfZeroes = Math.round(diff).toString().length;
        pow = Math.pow(10, numOfZeroes)
        result = result + pow
        return result
    }

    const getCurrentMonthProduction = () => {
        let temp = 0;
        if (currentMonth && currentMonth.length > 0) {
            currentMonth.forEach(day => {
                temp += day.value;
            })
        }
        let result = {
            "value": temp
        };
        return result;
    }

    return (
        <div className={`relative flex w-full h-5/6 items-end pr-10`} style={{marginTop: '11vh'}}>
            <div className={'vbcbg_lines vbcbgl-1'}></div>
            <div className={'vbcbg_lines vbcbgl-2'}>{formatNumber(getMaxValue())} kWh</div>
            <div className={'vbcbg_lines vbcbgl-3'}>{formatNumber(getMaxValue() * 0.75)} kWh</div>
            <div className={'vbcbg_lines vbcbgl-4'}>{formatNumber(getMaxValue() / 2)} kWh</div>
            <div className={'vbcbg_lines vbcbgl-5'}>{formatNumber(getMaxValue() / 4)} kWh</div>
            <div className={`w-1/12 ml-2`}/>
            {data && data.length > 0 ?
                data
                    //.concat(getCurrentMonthProduction())
                    .map((item, index) => (
                        <div
                            key={`chart_bar_${index}`}
                            className={`relative flex items-end h-full z-10 ml-4`}
                            style={{
                                width: '11%',
                                justifyContent: 'center'
                            }}
                        >   
                            { item && 
                                <>
                                    <div
                                        className={`relative z-10 bg-new-green-100 dark:bg-new-green-100 rounded-sm lineanimation`}
                                        style={{
                                            height: `${item.current ? (item.current.value / getMaxValue()) * 100 : 0}%`,
                                            //border: 'solid 2px green',
                                            borderTopLeftRadius: '1rem',
                                            borderTopRightRadius: '1rem',
                                            border: currentMonthNumber === index ? 'dashed .125rem #ded' : '',
                                            marginLeft: '4%',
                                            marginRight: '4%',
                                            width: '20%',
                                            backgroundColor: primaryColor ? primaryColor : '#5D9A79',
                                        }}
                                    >

                                    </div>
                                    <div
                                        className={`relative z-10 bg-new-green-500 dark:bg-new-green-500 rounded-sm lineanimation`}
                                        style={{
                                            height: `${(item.previous ? item.previous.value / getMaxValue() : 0) * 100}%`,
                                            //border: 'solid 2px green',
                                            borderTopLeftRadius: '1rem',
                                            borderTopRightRadius: '1rem',
                                            marginLeft: '4%',
                                            marginRight: '4%',
                                            width: '20%',
                                            backgroundColor: secondaryColor ? secondaryColor : '#2E443E',
                                        }}
                                    >
                                    </div>
                                </>
                            }

                            <h1
                                className={'absolute capitalize text-2xl w-full text-center text-dark-grayscale-8 dark:text-dark-grayscale-1'}
                                style={{
                                    bottom: '-8%'
                                }}>
                                {/*`${moment(item.current.timestamp).subtract(1, 'months').format('MMM')}`.slice(0, 3)*/}
                                {`${moment(index + 1, 'M').format('MMM')}`.slice(0, 3)}
                            </h1>
                        </div>
                    ))
                : false
            }
            {data && data.length ? <EmptyBars count={12 - data.length}/> : ''}
            <div className={`w-1/12`}/>
        </div>
    )
}

export default VerticalBarChartNew;