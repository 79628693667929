import { useState, useEffect } from "react";
import { formatNumber } from "../helpers/numeral";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllLocations,
  setCurrentLocation,
} from "../redux/actions/slideshow";
import { Trans } from "react-i18next";
import EmLogo from "../assets/images/em_logo.svg";
import EmLogoWhite from "../assets/images/em_logo_all_white_twolines.svg";
import SloganWhite from "../assets/images/slogan_white.svg";
import HistoricProduction from "../components/cards/HistoricProduction";
import EnergyPurchased12M from "../components/cards/EnergyPurchased12M";
import EnergySold12M from "../components/cards/EnergySold12M";
import SolarInstallations from "../components/cards/SolarInstallations";
import EnergyProduction from "../components/cards/EnergyProduction";
import EnergyConsumption from "../components/cards/EnergyConsumption";
import EnergyProduced7D from "../components/cards/EnergyProduced7D";
import EnergyPurchased7D from "../components/cards/EnergyPurchased7D";
import EnergySold7D from "../components/cards/EnergySold7D";
import NetZero from "../components/cards/NetZero";
import FlowChart from "../components/charts/FlowChart";
import SankeyChart from "../components/charts/SankeyChart/SankeyChart";
import Building from "../assets/images/building-placeholder.png";
import SolarBg from "../assets/images/solar-bg.png";
import WeatherWidgetBg from "../assets/images/weather-widget-background.png";
import NoDataIcon from "../assets/icons/nodata.svg";
import BuildingDark from "../assets/images/building-placeholder-dark.png";
import { getGaugePercentage } from "../helpers/functions";
import { sleep } from "../helpers/promises";

const BuildingDetails = ({
  slide,
  isLightTheme,
  language,
  slideIndex,
  tempIndex,
  isPaused,
  newTopForBuildingDetails,
}) => {
  const dispatch = useDispatch();
  const allSlides = useSelector((state) => state.slideshow.slides);
  const lightTheme = useSelector((state) => state.slideshow.lightTheme);
  const organization = useSelector((state) => state.slideshow.organization);
  const organizationLogo = organization.logoFullSVG
    ? btoa(unescape(encodeURIComponent(organization.logoFullSVG)))
    : undefined;
  const partnerLogo = organization.partner?.imageBase64

  const [isSimpleCapacityBox, setIsSimpleCapacityBox] = useState(undefined);
  const [tempTop, setTempTop] = useState(0);
  const [capacity, setCapacity] = useState(1);
  const startTime = new Date().getTime();

  const getSolarProduction = () => {
    let res = 0;
    if (slide && slide.slideEnergy2 && slide.slideEnergy2.liveFlow) {
      slide.slideEnergy2.liveFlow.forEach((e) => {
        let meter = slide.slideEnergy2.tags.find((x) => x.id === e.id);
        if (
          meter &&
          meter.meterConfiguration &&
          meter.meterConfiguration.meterType === "INTERNALLY_PRODUCED" &&
          meter.tagType === "POWER" &&
          e.data &&
          e.data.length
        )
          res += e.data[e.data.length - 1].value;
      });
    }
    return res;
  };

  const getBatteryCharge = () => {
    let res = 0;
    let found = false;
    if (slide && slide.slideEnergy2 && slide.slideEnergy2.liveFlow) {
      slide.slideEnergy2.liveFlow.forEach((e) => {
        let meter = slide.slideEnergy2.tags.find((x) => x.id === e.id);
        if (
          meter &&
          meter.meterConfiguration &&
          meter.meterConfiguration.meterType === "CHARGE" &&
          meter.tagType === "POWER" &&
          e.data &&
          e.data.length
        ) {
          res += e.data[e.data.length - 1].value;
          found = true;
        }
      });
    }
    if (!found) return null;
    return res;
  };

  const getBatteryStatus = () => {
    let res = 0;
    let found = false;
    if (slide && slide.slideEnergy2 && slide.slideEnergy2.liveFlow) {
      slide.slideEnergy2.liveFlow.forEach((e) => {
        let meter = slide.slideEnergy2.tags.find((x) => x.id === e.id);
        if (
          meter &&
          meter.meterConfiguration &&
          meter.meterConfiguration.meterType === "BATTERYSTATUS" &&
          meter.tagType === "BATTERY" &&
          e.data &&
          e.data.length
        ) {
          res += e.data[e.data.length - 1].value;
          found = true;
        }
      });
    }
    if (!found) return null;
    return res;
  };

  const getSolarEnergyPercentage = () => {
    return Math.abs(
      (
        (getSolarProduction() / (getGridConsumption() + getSolarProduction())) *
        100
      ).toFixed(0)
    );
  };

  const getGridConsumption = () => {
    let res = 0;
    if (slide && slide.slideEnergy2 && slide.slideEnergy2.liveFlow) {
      slide.slideEnergy2.liveFlow.forEach((e) => {
        let meter = slide.slideEnergy2.tags.find((x) => x.id === e.id);
        if (
          meter &&
          meter.meterConfiguration &&
          meter.meterConfiguration.meterType === "EXTERNALLY_IN" &&
          e.data &&
          e.data.length
        )
          res += e.data[e.data.length - 1].value;
      });
    }
    return res;
  };

  const getWeatherTemperature = () => {
    let forecast = slide.slideEnergy2.asset.currentWeather.forecast;
    return forecast[forecast.length - 1].temperature;
  };

  const getWeatherIcon = () => {
    if (slide.slideEnergy2 && slide.slideEnergy2.asset) {
      let forecast = slide.slideEnergy2.asset.currentWeather.forecast;
      return forecast[forecast.length - 1].symbol;
    }
    return "";
  };

  useEffect(() => {
    if (allSlides && allSlides.length > 0) {
      let tempLocations = [];
      allSlides.map((item, index) => {
        if (
          item.slideType === "ENERGY" &&
          item.slideEnergy2 &&
          item.slideEnergy2.asset &&
          item.slideEnergy2.asset.location &&
          item.slideEnergy2.asset.location.location &&
          item.slideEnergy2.asset.location.location.coordinates
        ) {
          tempLocations.push({
            lat: item.slideEnergy2.asset.location.location.coordinates[1],
            lng: item.slideEnergy2.asset.location.location.coordinates[0],
          });
        }
        if (index === allSlides.length - 1) {
          dispatch(setAllLocations(tempLocations));
          tempLocations = [];
        }
      });
    }

    if (
      slide &&
      slide.slideEnergy2 &&
      slide.slideEnergy2.asset &&
      slide.slideEnergy2.asset.location &&
      slide.slideEnergy2.asset.location.location &&
      slide.slideEnergy2.asset.location.location.coordinates
    ) {
      dispatch(
        setCurrentLocation(
          slide.slideEnergy2.asset.location.location.coordinates[1],
          slide.slideEnergy2.asset.location.location.coordinates[0]
        )
      );
    }
  }, [allSlides, slide]);

  useEffect(() => {
    if (tempIndex === slideIndex) {
      //startVerticalSliding(1)
      console.log("startVerticalSliding escaped");
    } else {
      let buildingCharts = document.getElementById(
        "buildingCharts" + slideIndex
      );
      if (buildingCharts) {
        buildingCharts.setAttribute("style", "top: 100% !important");
        buildingCharts.setAttribute("style", "top: 0% !important");
      }
    }
  }, [tempIndex]);

  useEffect(() => {
    checkCapacityBox(newTopForBuildingDetails.value);
  }, [newTopForBuildingDetails]);
  useEffect(() => {
    if (tempIndex === slideIndex && getNumberOfSlides()) {
      startVerticalSliding(2);
    }
  }, [isPaused, tempIndex]);

  const startVerticalSliding = async (option) => {
    if (isPaused) return;
    await sleep(100);
    let buildingCharts = document.getElementById("buildingCharts" + slideIndex);
    let slideDuration = Number(buildingCharts.getAttribute("slide-duration"));
    let numberOfVerticalSliides = Number(
      buildingCharts.getAttribute("number-of-slides")
    );
    if (buildingCharts) {
      let getSeconds = (new Date().getTime() - startTime) / 1000;
      console.log("Time passed: " + getSeconds + " seconds");
      console.log(buildingCharts.style.top);
      let oldTop = buildingCharts.style.top;
      let newTop = 0;
      if (option === 1) {
        newTop = 0;
      } else if (oldTop && oldTop !== "0%") {
        newTop = Number(oldTop.substring(1, 4)) + 100;
        if (newTop >= numberOfVerticalSliides * 100) newTop = 0;
      } else {
        newTop = 100;
      }
      if (option !== 2) {
        buildingCharts.setAttribute(
          "style",
          "top: -" + newTop + "% !important"
        );
        checkCapacityBox(newTop);
        setTempTop(newTop / 100);
      }
    }
    await sleep((slideDuration / numberOfVerticalSliides) * 1000);
    startVerticalSliding();
  };

  const checkCapacityBox = (position) => {
    if (isSolarInstallationsShown() && isCapacity(position)) {
      let installation = slide.slideEnergy2.solarInstallations[position / 100];
      setCapacity(installation.installedSolarPanelCapacity);
      let tag = slide.slideEnergy2.liveFlow.find(
        (x) => x.id === installation.id
      );
      console.log(installation, "cap usage");
      console.log(slide.slideEnergy2.liveFlow, "cap usage");
      setIsSimpleCapacityBox(false);
    } else setIsSimpleCapacityBox(true);
  };

  const isCapacity = (position) => {
    return position <= (slide.slideEnergy2.solarInstallations.length - 1) * 100;
  };

  const isNetZeroChartShown = () => {
    return false;
    if (slide && slide.slideEnergy2 && slide.slideEnergy2.tags) {
      let EX_OUT_tags = slide.slideEnergy2.tags.find(
        (t) => t.meterConfiguration.meterType === "EXTERNALLY_OUT"
      );
      if (EX_OUT_tags) return true;
    }
    return false;
  };

  const getNumberOfSlides = () => {
    let numberOfSlides = 0;
    if (isSolarInstallationsShown())
      numberOfSlides += slide.slideEnergy2.solarInstallations.length;
    if (isNetZeroChartShown()) numberOfSlides++;
    if (isLiveShown()) numberOfSlides++;
    if (isProductionShown()) numberOfSlides++;
    if (isPurchaseShown()) numberOfSlides++;
    if (isEnergyPurchased7DShown()) numberOfSlides++;
    if (isEnergyProduced7DShown()) numberOfSlides++;
    if (isEnergySold7DShown()) numberOfSlides++;
    if (isEnergyFlow7DShown()) numberOfSlides++;
    if (isEnergyFlow1YShown()) numberOfSlides++;
    if (isProduction1YShown()) numberOfSlides++;
    if (isConsumption1YShown()) numberOfSlides++;
    if (isSold1YShown()) numberOfSlides++;
    return numberOfSlides;
  };

  const isLiveShown = () => {
    if (slide && slide.slideEnergy2 && slide.slideEnergy2.liveFlow) {
      let INTERNALLY_PRODUCED_tags = slide.slideEnergy2.tags.find(
        (t) =>
          t.meterConfiguration.meterType === "INTERNALLY_PRODUCED" &&
          t.tagType === "POWER"
      );
      let EXTERNALLY_IN_tags = slide.slideEnergy2.tags.find(
        (t) =>
          t.meterConfiguration.meterType === "EXTERNALLY_IN" &&
          t.tagType === "POWER"
      );
      if (INTERNALLY_PRODUCED_tags && EXTERNALLY_IN_tags) return true;
    }
    return false;
  };

  const isProductionShown = () => {
    return false;
    if (slide && slide.slideEnergy2 && slide.slideEnergy2.flowChart) {
      let INTERNALLY_PRODUCED_tags = slide.slideEnergy2.tags.find(
        (t) =>
          t.meterConfiguration.meterType === "INTERNALLY_PRODUCED" &&
          t.tagType === "POWER"
      );
      console.log(INTERNALLY_PRODUCED_tags, "INTERNALLY_PRODUCED_tags");
      if (INTERNALLY_PRODUCED_tags) return true;
    }
    return false;
  };

  const isEnergyProduced7DShown = () => {
    if (
      slide &&
      slide.slideEnergy2 &&
      slide.slideEnergy2.energyProfile7D &&
      slide.slideEnergy2.energyProfile7D.length
    ) {
      let foundTags = slide.slideEnergy2.tags.find(
        (t) =>
          t.meterConfiguration.meterType === "INTERNALLY_PRODUCED" &&
          t.tagType === "ENERGYMETER"
      );
      if (foundTags) return true;
    }
    return false;
  };

  const isEnergyPurchased7DShown = () => {
    if (
      slide &&
      slide.slideEnergy2 &&
      slide.slideEnergy2.energyProfile7D &&
      slide.slideEnergy2.energyProfile7D.length
    ) {
      let foundTags = slide.slideEnergy2.tags.find(
        (t) =>
          t.meterConfiguration.meterType === "EXTERNALLY_IN" &&
          t.tagType === "ENERGYMETER"
      );
      if (foundTags) return true;
    }
    return false;
  };

  const isEnergySold7DShown = () => {
    if (
      slide &&
      slide.slideEnergy2 &&
      slide.slideEnergy2.energyProfile7D &&
      slide.slideEnergy2.energyProfile7D.length
    ) {
      let foundTags = slide.slideEnergy2.tags.find(
        (t) =>
          t.meterConfiguration.meterType === "EXTERNALLY_OUT" &&
          t.tagType === "ENERGYMETER"
      );
      if (foundTags) return true;
    }
    return false;
  };

  const isEnergyFlow7DShown = () => {
    if (slide && slide.slideEnergy2 && slide.slideEnergy2.energyFlow7D)
      return true;
    return false;
  };

  const isEnergyFlow1YShown = () => {
    if (slide && slide.slideEnergy2 && slide.slideEnergy2.energyFlow1Y)
      return true;
    return false;
  };

  const isProduction1YShown = () => {
    if (
      slide &&
      slide.slideEnergy2 &&
      slide.slideEnergy2.energyProfile1Y &&
      slide.slideEnergy2.energyProfile1Y.length
    ) {
      let foundTags = slide.slideEnergy2.tags.find(
        (t) =>
          t.meterConfiguration.meterType === "INTERNALLY_PRODUCED" &&
          t.tagType === "ENERGYMETER"
      );
      if (foundTags) return true;
    }
    return false;
  };

  const isConsumption1YShown = () => {
    if (
      slide &&
      slide.slideEnergy2 &&
      slide.slideEnergy2.energyProfile1Y &&
      slide.slideEnergy2.energyProfile1Y.length
    ) {
      let foundTags = slide.slideEnergy2.tags.find(
        (t) =>
          t.meterConfiguration.meterType === "EXTERNALLY_IN" &&
          t.tagType === "ENERGYMETER"
      );
      if (foundTags) return true;
    }
    return false;
  };

  const isSold1YShown = () => {
    if (
      slide &&
      slide.slideEnergy2 &&
      slide.slideEnergy2.energyProfile1Y &&
      slide.slideEnergy2.energyProfile1Y.length
    ) {
      let foundTags = slide.slideEnergy2.tags.find(
        (t) =>
          t.meterConfiguration.meterType === "EXTERNALLY_OUT" &&
          t.tagType === "ENERGYMETER"
      );
      if (foundTags) return true;
    }
    return false;
  };

  const isPurchaseShown = () => {
    return false;
    if (slide && slide.slideEnergy2 && slide.slideEnergy2.flowChart) {
      let INTERNALLY_PRODUCED_tags = slide.slideEnergy2.tags.find(
        (t) =>
          t.meterConfiguration.meterType === "INTERNALLY_PRODUCED" &&
          t.tagType === "POWER"
      );
      let EXTERNALLY_IN_tags = slide.slideEnergy2.tags.find(
        (t) =>
          t.meterConfiguration.meterType === "EXTERNALLY_IN" &&
          t.tagType === "POWER"
      );
      if (EXTERNALLY_IN_tags && !INTERNALLY_PRODUCED_tags) return true;
    }
    return false;
  };

  const isSolarInstallationsShown = () => {
    if (
      slide &&
      slide.slideEnergy2 &&
      slide.slideEnergy2.solarInstallations &&
      slide.slideEnergy2.solarInstallations.length
    )
      return true;
    return false;
  };

  return (
    <div className={"w-screen h-screen"}>
      <div
        className={
          "absolute z-10 top-0 left-0 right-0 h-2 bg-light-qualitative-magenta"
        }
      />
      {slide && slide.slideEnergy2 ? (
        <div
          className={
            "relative flex w-full h-full bg-light-semantic-green-50 dark:bg-dark-grayscale-5"
          }
        >
          {/* BUILDING LOCATION SECTION*/}
          <div
            className={"relative h-full w-1/5"}
            style={{
              padding: 20,
            }}
          >
            <div
              className={
                "w-full bg-em-white-500 dark:bg-dark-grayscale-1 p-4 px-6 shadow"
              }
              style={{
                borderTopRightRadius: 16,
                borderTopLeftRadius: 16,
                height: "21.5%",
              }}
            >
              {organizationLogo ? (
                <div className={"w-full"}>
                  <img
                    className={"h-16 mb-4"}
                    alt="orglogo"
                    src={`data:image/svg+xml;base64,${organizationLogo}`}
                  />
                </div>
              ) : (
                <div className={"w-full"}>
                  <h2
                    className={"text-3x-m font-bold mt-3 text-dark-grayscale-8"}
                  >
                    {organization.name}
                  </h2>
                </div>
              )}
              <h2 className={"text-3xl font-bold mt-3 text-dark-grayscale-8 FamiljenGrotesk"}>
                {slide &&
                slide.slideEnergy2 &&
                slide.slideEnergy2.asset &&
                slide.slideEnergy2.asset.name
                  ? slide.slideEnergy2.asset.name
                  : false}
              </h2>
              <h4 className={"text-dark-grayscale-8"}>
                {slide &&
                slide.slideEnergy2 &&
                slide.slideEnergy2.asset &&
                slide.slideEnergy2.asset.location
                  ? slide.slideEnergy2.asset.location.streetAddress1
                    ? slide.slideEnergy2.asset.location.streetAddress1
                    : slide.slideEnergy2.asset.location.streetAddress2
                    ? slide.slideEnergy2.asset.location.streetAddress2
                    : false
                  : false}
              </h4>
            </div>
            <div className={"w-full h-1/4 shadow"}>
              <img
                src={
                  slide &&
                  slide.slideEnergy2 &&
                  slide.slideEnergy2.asset &&
                  slide.slideEnergy2.asset.image &&
                  slide.slideEnergy2.asset.image.url
                    ? slide.slideEnergy2.asset.image.url
                    : lightTheme
                    ? Building
                    : BuildingDark
                }
                alt={"Building"}
                className={"w-full h-full object-cover shadow"}
                style={{
                  borderBottomRightRadius: 16,
                  borderBottomLeftRadius: 16,
                }}
              />
            </div>

            <div
              className={
                "flex justify-center items-center bg-dark-grayscale-1 dark:bg-dark-grayscale-6"
              }
              style={{
                borderTopRightRadius: 8,
                backgroundImage: `url(${SolarBg})`,
                backgroundSize: "cover",
                margin: "1vh 0",
                height: "21.5vh",
                borderRadius: 16,
              }}
            >
              {isSimpleCapacityBox ? (
                <div
                  className={
                    "text-dark-grayscale-8 dark:text-dark-grayscale-1 text-center"
                  }
                >
                  {slide &&
                  slide.slideEnergy2 &&
                  slide.slideEnergy2.asset &&
                  slide.slideEnergy2.asset.building &&
                  slide.slideEnergy2.asset.building
                    .installedSolarPanelCapacity ? (
                    <>
                      <h2 className={"text-3xl font-bold mt-0"}>
                        {" "}
                        <b className={"text-5xl font-bold mt-0"}>
                          {
                            slide.slideEnergy2.asset.building
                              .installedSolarPanelCapacity
                          }
                        </b>{" "}
                        kW
                      </h2>
                      <span
                        className={"uppercase mt-1"}
                        style={{ letterSpacing: "4px" }}
                      >
                        <Trans i18nKey={"installedSolarCapacity"}>
                          Installed solar capacity
                        </Trans>
                      </span>
                    </>
                  ) : (
                    false
                  )}
                </div>
              ) : (
                <div className="effektprogress">
                  <div
                    className="progress-card"
                    style={{ transform: "scale(.8)" }}
                  >
                    <div className="percent">
                      <svg>
                        <defs>
                          <linearGradient
                            id="gradient-1"
                            x1="40%"
                            y1="40%"
                            x2="100%"
                            y2="100%"
                          >
                            <stop offset="0%" stopColor="#11ed76" />
                            <stop offset="100%" stopColor="#11ed7600" />
                          </linearGradient>
                        </defs>
                        <circle cx="105" cy="105" r="100"></circle>
                        <circle
                          cx="105"
                          cy="105"
                          r="100"
                          style={{
                            "--percent": getGaugePercentage(
                              isNaN(getSolarProduction())
                                ? 0
                                : getSolarProduction()
                            ),
                          }}
                        ></circle>
                      </svg>
                      <div className="number">
                        <h3
                          className="text-dark-grayscale-8 dark:text-dark-grayscale-1"
                          style={{ marginBottom: "0", fontSize: "3vw" }}
                        >
                          {((getSolarProduction() / capacity) * 100).toFixed(0)}
                          <span style={{ marginBottom: "0", fontSize: "2vw" }}>
                            %
                          </span>
                        </h3>
                        <div className="title" style={{ marginTop: "-1.4rem" }}>
                          <h2
                            style={{
                              marginBottom: "0vw",
                              marginTop: ".9vw",
                              fontSize: ".9vw",
                            }}
                          ></h2>
                        </div>
                        <h2
                          className="text-new-green-100"
                          style={{ fontSize: ".9vw", margin: ".8vw" }}
                        >
                          {
                            <Trans
                              values={{ value: capacity + "kW" }}
                              i18nKey={"solar.producedAndCapacity"}
                            >
                              of {capacity} kW installed power
                            </Trans>
                          }
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div
              className={"w-full h-1/4 shadow"}
              style={{
                borderRadius: 16,
              }}
            >
              <div
                className={
                  "flex flex-col justify-center items-center w-full text-dark-grayscale-8 dark:text-dark-grayscale-1 bg-dark-grayscale-1 dark:bg-dark-grayscale-6"
                }
                style={{
                  height: partnerLogo ? '12vh' : "18vh",
                  borderRadius: 16,
                  backgroundImage: `url(${WeatherWidgetBg})`,
                  backgroundSize: "cover",
                }}
              >
                <div
                  className={"uppercase "}
                  style={{ letterSpacing: ".2vw", fontSize: ".8vw"}}
                >
                  {
                    <Trans
                      values={{ value: capacity + "kW" }}
                      i18nKey={"weatherNow"}
                    >
                      Weather now
                    </Trans>
                  }
                </div>
                <div
                  className={
                    "font-semibold flex flex-row items-center justify-center"
                  }
                  style={{ fontSize: "1.6em", width: "100%" }}
                >
                  <img
                    className={"mr-2"}
                    src={`/weather/${getWeatherIcon()}.svg`}
                    alt={"icon"}
                    style={{
                      fill: "white",
                      height: "2.5vh",
                    }}
                  />
                  <div>
                    <span style={{ fontSize: "2vw" }}>
                      {formatNumber(getWeatherTemperature(), 1)}
                    </span>
                    <span style={{ fontSize: "1vw", marginLeft: "0.2em" }}>
                      °C
                    </span>
                  </div>
                </div>
              </div>

              {partnerLogo && 
                <div
                className={
                  "flex flex-row items-center w-full text-dark-grayscale-8 dark:text-dark-grayscale-1 bg-dark-grayscale-1 dark:bg-dark-grayscale-6"
                }
                style={{
                  height: '7vh',
                  marginTop: "1vh",
                  borderRadius: 16,
                }}
              >
                  <div
                    className={
                      "font-semibold flex flex-col items-center justify-center"
                    }
                    style={{ fontSize: "1.6em", width: "100%" }}
                  >
                    <img
                      src={partnerLogo}
                      alt={"partner"}
                      style={{ height: "4.5vh", marginBottom: ".05vh" }}
                    />
                </div>
              </div>
              }


              <div
                className={
                  "flex flex-row items-center w-full text-dark-grayscale-8 dark:text-dark-grayscale-1 bg-dark-grayscale-1 dark:bg-dark-grayscale-6"
                }
                style={{
                  height: partnerLogo ? "7vh" : '9vh',
                  marginTop: "1vh",
                  borderRadius: 16,
                }}
              >
                <div
                  className={
                    "font-semibold flex flex-col items-center justify-center"
                  }
                  style={{ fontSize: "1.6em", width: "100%" }}
                > 
                  {isLightTheme ? (
                    <div class="slogan__animation" >
                      <div>
                        <img
                          class="slogan__animation__logo"
                          src={EmLogoWhite}
                          alt={"EmLogoWhite"}
                          style={{ height: "4.5vh" }}
                        />
                        <img
                          className="slogan__animation__slogan"
                          src={SloganWhite}
                          alt={"SloganWhite"}
                          style={{ height: "4.5vh" }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div class="slogan__animation" >
                      <div>
                        <img
                          class="slogan__animation__logo"
                          src={EmLogoWhite}
                          alt={"EmLogoWhite"}
                          style={{ height: "4.5vh" }}
                        />
                        <img
                          className="slogan__animation__slogan"
                          src={SloganWhite}
                          alt={"SloganWhite"}
                          style={{ height: "4.5vh" }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              " buildingCharts--mainWrapper w-4/5 h-full bg-dark-grayscale-1 dark:bg-dark-grayscale-6"
            }
            style={{ borderRadius: 16 }}
          >
            <div
              className={"buildingCharts buildingCharts-" + getNumberOfSlides()}
              number-of-slides={getNumberOfSlides()}
              slide-duration={slide.durationInSeconds}
              id={"buildingCharts" + slideIndex}
            >
              {tempIndex === slideIndex ? (
                <>
                  {isSolarInstallationsShown() &&
                    slide.slideEnergy2.solarInstallations.map(
                      (solarInstalation, index) => (
                        <div
                          className={`buildingChart w-full h-full`}
                          key={"si" + index}
                        >
                          <SolarInstallations
                            solarInstalation={solarInstalation}
                            tempTop={tempTop}
                            index={index}
                          ></SolarInstallations>
                        </div>
                      )
                    )}

                  {isProductionShown() && (
                    <div className={`buildingChart w-full h-full `}>
                      <EnergyProduction slide={slide}></EnergyProduction>
                    </div>
                  )}

                  {isPurchaseShown() && (
                    <div className={`buildingChart w-full h-full `}>
                      <EnergyConsumption slide={slide}></EnergyConsumption>
                    </div>
                  )}

                  {isLiveShown() && (
                    <FlowChart
                      totalConsumption={
                        getGridConsumption() + getSolarProduction()
                      }
                      fromGrid={getGridConsumption()}
                      solarProduction={getSolarProduction()}
                      batteryCharge={getBatteryCharge()}
                      batteryStatus={getBatteryStatus()}
                      solarEnergyPercentage={getSolarEnergyPercentage()}
                      solarEnergyCapacityPercentage={
                        getSolarProduction() /
                        slide.slideEnergy2.asset.building
                          .installedSolarPanelCapacity
                      }
                    />
                  )}

                  {isEnergyProduced7DShown() && (
                    <div className={`buildingChart w-full h-full `}>
                      <EnergyProduced7D
                        isLightTheme={isLightTheme}
                        slide={slide}
                      ></EnergyProduced7D>
                    </div>
                  )}

                  {isEnergyPurchased7DShown() && (
                    <div className={`buildingChart w-full h-full `}>
                      <EnergyPurchased7D slide={slide}></EnergyPurchased7D>
                    </div>
                  )}

                  {isEnergySold7DShown() && (
                    <div className={`buildingChart w-full h-full `}>
                      <EnergySold7D slide={slide}></EnergySold7D>
                    </div>
                  )}

                  {isEnergyFlow7DShown() && (
                    <SankeyChart
                      isLightTheme={isLightTheme}
                      lang={language}
                      flowwData={slide.slideEnergy2.energyFlow7D}
                      slideIndex={slideIndex}
                      is7days={true}
                    />
                  )}

                  {isEnergyFlow1YShown() && (
                    <SankeyChart
                      isLightTheme={isLightTheme}
                      lang={language}
                      flowwData={slide.slideEnergy2.energyFlow1Y}
                      slideIndex={slideIndex}
                      is7days={false}
                    />
                  )}

                  {isProduction1YShown() && (
                    <div className={` buildingChart w-full h-full rounded-xl `}>
                      <HistoricProduction
                        title={
                          <Trans i18nKey={"solar.production"}>Production</Trans>
                        }
                        subtitle={
                          <Trans i18nKey={"common:yearToDate"}>
                            Year to date
                          </Trans>
                        }
                        slideIndex={slideIndex}
                      />
                    </div>
                  )}

                  {isConsumption1YShown() && (
                    <div className={` buildingChart w-full h-full rounded-xl `}>
                      <EnergyPurchased12M
                        title={
                          <Trans i18nKey={"energyPurchased"}>Purchased</Trans>
                        }
                        subtitle={
                          <Trans i18nKey={"common:yearToDate"}>
                            Year to date
                          </Trans>
                        }
                        slideIndex={slideIndex}
                      />
                    </div>
                  )}

                  {isSold1YShown() && (
                    <div className={` buildingChart w-full h-full rounded-xl `}>
                      <EnergySold12M
                        title={
                          <Trans i18nKey={"energySold"}>Sold</Trans>
                        }
                        subtitle={
                          <Trans i18nKey={"common:yearToDate"}>
                            Year to date
                          </Trans>
                        }
                        slideIndex={slideIndex}
                      />
                    </div>
                  )}

                  {isNetZeroChartShown() ? (
                    <div className={` w-full h-full rounded-xl `}>
                      <NetZero
                        title={
                          <Trans i18nKey={"nettChart.title"}>
                            Purchased sold net
                          </Trans>
                        }
                        subtitle={
                          <Trans i18nKey={"common:yearToDate"}>
                            Year to date
                          </Trans>
                        }
                        purchased={
                          <Trans i18nKey={"common:purchased"}>Purchased</Trans>
                        }
                        sold={<Trans i18nKey={"common:sold"}>Sold</Trans>}
                        net={
                          <Trans i18nKey={"common:energyFlowEnum.NET"}>
                            Net
                          </Trans>
                        }
                        netTotals={slide.slideEnergy2.netTotals}
                        netProfile={slide.slideEnergy2.netProfile}
                        tags={slide.slideEnergy2.tags}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <div
                  className={"relative w-full h-full p-6 rounded-xl"}
                  style={{ overflow: "hidden" }}
                >
                  {" "}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={
            "relative flex w-full h-full bg-light-semantic-green-50 dark:bg-dark-grayscale-5"
          }
        >
          <div className="nodata text-dark-grayscale-8 dark:text-dark-grayscale-1">
            <img src={NoDataIcon} alt="nodata" style={{ width: "8vw" }}></img>
            <h2>No data for this slide</h2>
            <h3 className="text-3x-m">Pelase check you configuration</h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default BuildingDetails;
